import React, { useEffect } from 'react'
import '../styles/promoscreen.css'
import ReactPlayer from 'react-player/lazy'

import Nav from '../components/Nav'
import Footer from '../components/Footer'

const PromoScreen = () => {
    const videoUrl = "https://youtu.be/0bl_TooAhYc?si=JeLedkMa44P-CLwj";

    useEffect(() => {
        document.title = `UJ x JODDRRY Orange Couture 2025`;
    }, []);

    return (
        <>
            <Nav />
            <section className='promoscreen-container'>
                <div className='promo-container'>
                    <ReactPlayer
                        url={videoUrl}
                        controls={true}
                        width="100%"
                        height="100%"
                        playing={false}        
                        loop={true}           
                        muted={false}    
                        lazy={true}      
                    />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default PromoScreen